/* eslint-disable */
// import axios from 'axios'
import Api from 'api';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import billingFrequencies from 'constants/billingFrequencies';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { createSelector } from 'reselect';
import { getPricingSelector, updatePremium, calculatePremium } from './quoteValues';
import { INIT_QUOTE } from './quote';
import { RECEIVE_AVAILABLE_PRODUCTS } from './availableProducts';
import { validateToken } from './user';
import format from 'date-fns/format';
import { addPackageDetails } from './quotePackages';
import { QUOTE_VERSION_V3, ID_THEFT_INDIVIDUAL_TEXT, ID_THEFT_FAMILY_TEXT } from 'constants/common';
import { PRODUCT_IDS } from 'constants/packages';
import { getEndorsement } from 'redux/modules/quotePackages';

// action types
export const RECEIVE_PRICING = 'ri/pricing/RECEIVE_PRICING';
const REQUEST_PRICING = 'ri/pricing/REQUEST_PRICING';
const REQUEST_PRICING_AFTER_LOGIN = 'ri/pricing/REQUEST_PRICING_AFTER_LOGIN';
export const CLEAR_PRICING = 'ri/pricing/CLEAR_PRICING';

// reducer
const initialState = {
  isStatePolicyFeeApplicable: false,
  statePolicyFee: 0,
  statePolicyMessage: '',
  statePolicyFigaFee: 0,
  statePolicyFigaeFee: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_PRICING:
      return payload;
    case CLEAR_PRICING:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const requestPricing = (payload) => ({
  type: REQUEST_PRICING,
  payload,
});

export const requestPricingAfterLogin = (payload) => ({
  type: REQUEST_PRICING_AFTER_LOGIN,
  payload,
});

// Selectors
const quoteSelector = (state) => state.quote;

export const premiumChargeSelector = createSelector(quoteSelector, (quote) => {
  const {
    ho4PolicyPremium,
    idTheftPolicyPremium,
    adminFee,
    isStatePolicyFeeApplicable,
    statePolicyFee,
    idTheftEndorsements,
    idTheftEndorsementId,
    individualEndorsementTypeId,
  } = quote;
  const rentersInsurance = parseFloat(parseFloat(ho4PolicyPremium).toFixed(2));

  const totalPremium = parseFloat(ho4PolicyPremium + idTheftPolicyPremium + adminFee).toFixed(2);
  var idTheftTitle = 'ID Theft';

  if (idTheftPolicyPremium) {
    const selectedIDTheftEndorsements = getEndorsement(idTheftEndorsements, idTheftEndorsementId);
    idTheftTitle =
      Number(individualEndorsementTypeId) === Number(selectedIDTheftEndorsements?.endorsementTypeId)
        ? ID_THEFT_INDIVIDUAL_TEXT
        : ID_THEFT_FAMILY_TEXT;
  }

  let premiumCharges = [
    {
      title: 'Renters Insurance',
      amount: rentersInsurance,
    },
    {
      title: 'Admin Fee',
      amount: adminFee,
    },
  ];

  if (idTheftPolicyPremium) {
    premiumCharges = [
      ...premiumCharges,
      {
        title: idTheftTitle,
        amount: idTheftPolicyPremium,
      },
    ];
  }

  let totalPremiumWithStateFee = 0;
  if (isStatePolicyFeeApplicable) {
    totalPremiumWithStateFee = (parseFloat(totalPremium) + parseFloat(statePolicyFee)).toFixed(2);
  }

  return {
    totalPremium,
    premiumCharges,
    totalPremiumWithStateFee,
  };
});

export const quoteChargesSelector = createSelector(quoteSelector, (quote) => {
  const { ho4PolicyPremium, idTheftPolicyPremium, adminFee } = quote;

  const combinedCharges = {
    charges: [
      {
        id: PRODUCT_IDS.HO4,
        title: 'Renters Insurance',
        amount: ho4PolicyPremium,
      },
      {
        id: PRODUCT_IDS.IdTheft,
        title: 'Id Theft',
        amount: idTheftPolicyPremium,
      },
      {
        id: 'admin_fee',
        title: 'Admin Fee',
        amount: adminFee,
      },
    ],
    total: parseFloat(parseFloat(ho4PolicyPremium + idTheftPolicyPremium + adminFee).toFixed(2)),
  };

  return combinedCharges;
});

// Helper functions
export const parsePricingData = ({ availableProducts, coverages }) => {
  return Object.entries(availableProducts).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: { ...value, ...coverages.products[key] },
    };
  }, {});
};

export const parseQuoteData = ({ availableProducts, coverages }, defaultBillingFrequency = 4) => {
  return Object.keys(availableProducts).reduce(
    ({ products, values }, key) => {
      products = {
        ...products,
        [key]: Object.entries(coverages.products[key]).reduce((accCoverages, [coverageKey, coverage]) => {
          if (coverageKey == 'carrierLimits') {
            return {
              ...accCoverages,
              carrierLimits: Object.entries(coverage).reduce((accLimits, [limitKey, { label, options }]) => {
                let valueKey = `carrierLimits_${limitKey}_${key}`;

                values = {
                  ...values,
                  [valueKey]: options.find((opt) => opt.isSelected).id,
                };

                return {
                  ...accLimits,
                  [limitKey]: {
                    name: limitKey,
                    label,
                    options,
                  },
                };
              }, {}),
            };
          } else if (coverageKey == 'endorsements') {
            let valueKey = `endorsements_${key}`;

            let endorsements = Object.entries(coverage).reduce(
              (
                acc,
                [
                  endorsementKey,
                  {
                    id,
                    name,
                    description,
                    isMandatory,
                    cost = null,
                    isSelected,
                    baseMultiplier = null,
                    baseRate = null,
                    endorsementTypeId = null,
                    adminFee = null,
                    premiumAmount = null,
                  },
                ],
              ) => {
                if (key == 5) {
                  let idTheft = Object.values(coverage).find((idTheft) => idTheft.isSelected) ?? {};
                  values = {
                    ...values,
                    [valueKey]: Object.values(idTheft).length ? idTheft.id : '',
                  };
                } else {
                  // Make sure endorsement value always inits to an array even if none are selected
                  let currentValue = Array.isArray(values[valueKey]) ? values[valueKey] : [];

                  values = {
                    ...values,
                    [valueKey]: isSelected ? [...currentValue, id] : currentValue,
                  };
                }

                return {
                  ...acc,
                  [endorsementKey]: {
                    id,
                    name,
                    description,
                    isMandatory,
                    cost,
                    baseMultiplier,
                    baseRate,
                    endorsementTypeId,
                    adminFee,
                    premiumAmount,
                  },
                };
              },
              {},
            );

            return {
              ...accCoverages,
              endorsements,
            };
          } else {
            return { ...accCoverages, [coverageKey]: coverage };
          }
        }, {}),
      };

      return { products, values };
    },
    {
      products: {},
      values: {
        billingFrequency: defaultBillingFrequency,
        defaultEnhancementEndorsement: coverages.products[1].enhancementEndorsementId || null,
      },
    },
  );
};

// sagas
function* getPricing({ payload = {} }) {
  const {
    propertyId,
    employeeId,
    leadSourceTypeId,
    defaultBillingFrequency,
    userId,
    cid,
    customerId,
    leaseId,
    applicantId,
    applicationId,
    selectedProperty,
    startDate,
    isAllowedMicroservice,
  } = yield select(
    ({
      getQuote: {
        property,
        employee,
        leadSourceType,
        user,
        clientId,
        customerId,
        leaseId,
        applicantId,
        applicationId,
        startDate,
      },
      properties,
    }) => ({
      propertyId: property,
      employeeId: employee.id,
      leadSourceTypeId: leadSourceType,
      defaultBillingFrequency: properties[property].minimumBillingFrequency,
      userId: user,
      cid: clientId || properties[property].clientId,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      selectedProperty: properties[property],
      startDate,
      isAllowedMicroservice: properties[property]?.isAllowedMicroservice,
    }),
  );

  yield put(setGlobalLoading(`Almost done! We're preparing your quote now.`));

  const { quoteVersion = 'v2' } = payload;

  try {
    const response = yield call(isAllowedMicroservice ? Api.getQuote : Api.getPricing, {
      propertyId,
      employeeId,
      quoteVersion,
      leadSourceTypeId,
      userId,
      cid,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      effectiveDate: format(startDate, 'MM/DD/YYYY'),
    });
    if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    let pricingData = parsePricingData(response);
    let quoteData = parseQuoteData(response, defaultBillingFrequency);
    //let billingFrequencies = parseBillingFrequencies(response)
    let {
      propertyDetails,
      totalPremium,
      showSubmitLater,
      availableProducts,
      isArrayEnrolmentEnabled,
      individualEndorsementTypeId,
      coverages: { products },
    } = response;

    if (selectedProperty.isGeneric) {
      propertyDetails = {
        ...propertyDetails,
        postalCode: selectedProperty.postalCode,
        city: selectedProperty.city,
        stateCode: selectedProperty.stateCode,
      };
    }

    let enhancementEndorsments = Object.values(products[1].endorsements).filter(
      (endorsment) => endorsment.isEnhancementEndorsement,
    );

    const { figaFee, figaeFee, isStatePolicyFeeApplicable, statePolicyFee, statePolicyMessage } = availableProducts[1];

    yield all([
      put({
        type: RECEIVE_PRICING,
        payload: pricingData,
      }),
      put({
        type: RECEIVE_AVAILABLE_PRODUCTS,
        payload: { availableProducts },
      }),
      put({
        type: INIT_QUOTE,
        payload: {
          products: quoteData.products,
          values: {
            ...quoteData.values,
            endorsements_5: quoteData.values?.endorsements_5 || '',
          },
          billingFrequencies,
          location: propertyDetails,
          totalPremium: totalPremium,
          showSubmitLater: showSubmitLater,
          enhancementEndorsments,
          statePolicyFigaFee: figaFee,
          statePolicyFigaeFee: figaeFee,
          isStatePolicyFeeApplicable: isStatePolicyFeeApplicable,
          statePolicyFee: statePolicyFee,
          statePolicyMessage: statePolicyMessage,
          isArrayEnrolmentEnabled: isArrayEnrolmentEnabled,
          individualEndorsementTypeId: individualEndorsementTypeId,
        },
      }),
    ]);

    if (quoteVersion === QUOTE_VERSION_V3) {
      yield put(addPackageDetails(response));
    }

    typeof payload.onComplete === 'function' && payload.onComplete();
    yield delay(1500);
    let premiumData = yield select(getPricingSelector);
    yield put(updatePremium(calculatePremium(premiumData, true)));
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* getPricingAfterLogin({ payload = {} }) {
  const {
    propertyId,
    employeeId,
    leadSourceTypeId,
    defaultBillingFrequency,
    userId,
    cid,
    customerId,
    leaseId,
    selectedProperty,
    startDate,
  } = yield select(
    ({
      getQuote: { property, employee, leadSourceType, user, clientId, customerId, leaseId, startDate },
      properties,
    }) => ({
      propertyId: property,
      employeeId: employee.id,
      leadSourceTypeId: leadSourceType,
      defaultBillingFrequency: properties[property].minimumBillingFrequency,
      userId: user,
      cid: clientId || properties[property].clientId,
      customerId,
      leaseId: leaseId,
      selectedProperty: properties[property],
      startDate,
    }),
  );
  yield put(setGlobalLoading("Almost done! We're preparing your quote now."));

  try {
    const response = yield call(Api.getPricingAfterLogin, {
      propertyId,
      employeeId,
      leadSourceTypeId,
      userId,
      cid,
      customerId,
      leaseId,
      entityId: payload.entityId,
      effectiveDate: format(startDate, 'MM/DD/YYYY'),
    });
    if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    let pricingData = parsePricingData(response);
    let quoteData = parseQuoteData(response, defaultBillingFrequency);
    let {
      propertyDetails,
      totalPremium,
      showSubmitLater,
      availableProducts,
      isArrayEnrolmentEnabled,
      individualEndorsementTypeId,
      coverages: { products },
    } = response;

    if (selectedProperty.isGeneric) {
      propertyDetails = {
        ...propertyDetails,
        postalCode: selectedProperty.postalCode,
        city: selectedProperty.city,
        stateCode: selectedProperty.stateCode,
      };
    }

    let enhancementEndorsments = Object.values(products[1].endorsements).filter(
      (endorsment) => endorsment.isEnhancementEndorsement,
    );

    const { figaFee, figaeFee, isStatePolicyFeeApplicable, statePolicyFee, statePolicyMessage } = availableProducts[1];

    yield all([
      put({
        type: RECEIVE_PRICING,
        payload: pricingData,
      }),
      put({
        type: RECEIVE_AVAILABLE_PRODUCTS,
        payload: { availableProducts },
      }),
      put({
        type: INIT_QUOTE,
        payload: {
          products: quoteData.products,
          values: quoteData.values,
          billingFrequencies,
          location: propertyDetails,
          totalPremium: totalPremium,
          showSubmitLater: showSubmitLater,
          enhancementEndorsments,
          statePolicyFigaFee: figaFee,
          statePolicyFigaeFee: figaeFee,
          isStatePolicyFeeApplicable: isStatePolicyFeeApplicable,
          statePolicyFee: statePolicyFee,
          statePolicyMessage: statePolicyMessage,
          isArrayEnrolmentEnabled: isArrayEnrolmentEnabled,
          individualEndorsementTypeId: individualEndorsementTypeId,
        },
      }),
    ]);

    typeof payload.onComplete === 'function' && payload.onComplete();
    yield delay(1500);
    let premiumData = yield select(getPricingSelector);
    yield put(updatePremium(calculatePremium(premiumData)));
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* watchGetPricing() {
  yield takeLatest(REQUEST_PRICING, getPricing);
}

function* watchGetPricingAfterLogin() {
  yield takeLatest(REQUEST_PRICING_AFTER_LOGIN, getPricingAfterLogin);
}

export const sagas = [watchGetPricing, watchGetPricingAfterLogin];
